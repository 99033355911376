import axios from 'axios'
import { makeAutoObservable } from 'mobx'
import * as moment from 'moment'
import { SUBSCRIPTION_LICENSE_TYPE, SUBSCRIPTION_STATUS } from '../constants/tenantConstants'
import { USER_ROLE } from '../constants/userRoleConstants'

export default class User {
  static PEOPLE_SCOPES = ['People.Read', 'User.ReadBasic.All']
  static MEETING_SCOPES = ['Calendars.Read.Shared', 'People.Read']

  user = {}
  name = 'John'
  mail = ''
  age = 42
  showAge = false
  isUserSet = false
  platform = ''
  displayName = ''
  graphAccessToken = ''
  oid = ''
  tenantId = ''
  isAdmin = false
  role = 0
  UPN = ''
  tenantSettings = null
  tenant = null
  hasGraphSubscription = false
  impersonateMode = false
  impersonateOldUser = null
  demoMode = false
  hasAdminConsent = false
  userSettings = {}
  canChangeGeneralSettings = true
  canAddRemoveUsers = true
  canStartEditCycles = true
  isPartner = false
  myPeopleErrorAccessDenied = false
  meetingErrorAccessDenied = false
  showAllowAccessBanner = false
  scopes = []

  constructor() {
    makeAutoObservable(this)
  }

  setUser(user) {
    this.displayName = user.displayName
    this.oid = user.oid
    this.graphAccessToken = user.graphAccessToken
    this.isUserSet = true
    this.UPN = user.UPN
    this.mail = user.mail
    this.tenantId = user.tenantId
    this.tenant = user.tenant
    this.tenantSettings = user.tenant.settings
    if (user.role === 2) {
      this.isAdmin = true
    }
    if (user.tenant.graphSubscription) {
      this.hasGraphSubscription = true
    }
    if (user.tenant.partnerUTMCode) {
      this.isPartner = true
    }
    this.role = user.role
    this.impersonateMode = user.impersonateMode
    this.impersonateOldUser = user.impersonateOldUser
    this.demoMode = user.demoMode
    this.hasAdminConsent = user.hasAdminConsent
    this.userSettings = user.userSettings
    this.canChangeGeneralSettings = user.canChangeGeneralSettings
    this.canAddRemoveUsers = user.canAddRemoveUsers !== undefined ? user.canAddRemoveUsers : true
    this.canStartEditCycles = user.canStartEditCycles !== undefined ? user.canStartEditCycles : true

    if (user.demoMode) {
      localStorage.setItem('demo', 'true')
      localStorage.setItem('user', user.displayName)
    }

    if (moment().diff(moment(user.createdAt), 'hour', true) < 1) {
      //@ts-ignore
      if (window.clarity) {
        //@ts-ignore
        window.clarity('set', 'usertype', 'newuser')
      }
    }

    if (moment().diff(moment(user.tenant.createdAt), 'hour', true) < 1) {
      //@ts-ignore
      if (window.clarity) {
        //@ts-ignore
        window.clarity('set', 'usertype', 'newtenant')
      }
    }

    if (user.scp) {
      this.scopes = user.scp.split(' ')
      const scopeSet = new Set(this.scopes)
      this.myPeopleErrorAccessDenied = User.PEOPLE_SCOPES.some(scope => !scopeSet.has(scope))
      this.meetingErrorAccessDenied = User.MEETING_SCOPES.some(scope => !scopeSet.has(scope))
      this.showAllowAccessBanner = this.myPeopleErrorAccessDenied
    }
  }
  setTenantSettings(settings) {
    this.tenantSettings = settings
    this.tenant.settings = settings
  }

  setNotificationType(type) {
    this.tenantSettings.forceNotificationType = type
  }

  setUserLicenseModel(userLicenseModel) {
    this.tenant.userLicenseModel = userLicenseModel
  }

  setTenantSecurityGroup(secGroup) {
    this.tenant.userLicenseModelSecurityGroup = secGroup
  }

  setTenant(tenant) {
    this.tenant = tenant
  }
  setUserSettings(settings) {
    this.userSettings = settings
  }

  toggleGraphSubscription(value) {
    this.hasGraphSubscription = value
  }

  isProfessionalSubscription() {
    return (
      this.isProfessionalTrial() ||
      (this.tenant.subscriptionStatus === SUBSCRIPTION_STATUS.ACTIVE &&
        this.tenant.subscriptionLicenseType === SUBSCRIPTION_LICENSE_TYPE.PROFESSIONAL)
    )
  }

  isEssentialSubscription() {
    return (
      this.tenant.subscriptionStatus === SUBSCRIPTION_STATUS.ACTIVE &&
      this.tenant.subscriptionLicenseType === SUBSCRIPTION_LICENSE_TYPE.ESSENTIAL
    )
  }

  isProfessionalTrial() {
    return (
      this.tenant.trialSubscription?.isExpired === false &&
      this.tenant.trialSubscription?.licenseType === SUBSCRIPTION_LICENSE_TYPE.PROFESSIONAL
    )
  }

  async fetchUserCount() {
    return await axios.get('/api/admin/getUserCount').then(res => {
      return res.data?.userCount
    })
  }

  isStartEditCyclesEnabled() {
    return this.role === USER_ROLE.ScopedAdministrator ? this.canStartEditCycles : true
  }

  isAddRemoveUsersEnabled() {
    return this.role === USER_ROLE.ScopedAdministrator ? this.canAddRemoveUsers : true
  }

  isChangeGeneralSettingsEnabled() {
    return this.role === USER_ROLE.ScopedAdministrator ? this.canChangeGeneralSettings : true
  }
}
