export const SUBSCRIPTION_STATUS = {
  FREE: 1,
  ACTIVE: 3,
  SUSPENDED: 4,
  CANCELLED: 5
}

export const SUBSCRIPTION_LICENSE_TYPE = {
  ESSENTIAL: 'Essential',
  PROFESSIONAL: 'Professional'
}
